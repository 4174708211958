// COPY PASTA FROM APP, we shoudl probably import from the same spot
// https://docs.amplify.aws/lib/client-configuration/configuring-amplify-categories/q/platform/js/#scoped-configuration-analytics

// const OLD_SERVER = {
//   aws_project_region: 'us-west-2',
//   aws_cognito_region: 'us-west-2',
//   aws_user_pools_id: 'us-west-2_98wyKU1mU', // (optional) -  Amazon Cognito User Pool ID
//   aws_user_pools_web_client_id: '2vf24te299bvo57qc06usvh7ec',
//   // (optional) - Users are not allowed to get the aws credentials unless they are signed in
//   // aws_mandatory_sign_in: 'enable',

//   aws_appsync_region: 'us-west-2', // (optional) - AWS AppSync region
//   aws_appsync_graphqlEndpoint: 'https://mrruolk5sbc3vgd4p4ius5qgzq.appsync-api.us-west-2.amazonaws.com/graphql',
//   aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
//   // graphql_endpoint: 'https://www.yourdomain.com/graphql', // (optional) - Custom GraphQL endpoint
//   // aws_appsync_authenticationType: 'API_KEY',
//   aws_appsync_apiKey: 'da2-wrzoudfudvgfxnfdc4uqzkgria', // (optional) - AWS AppSync API Key
//   // graphql_endpoint_iam_region: 'us-west-2' // (optional) - Custom IAM region
//   aws_appsync_userpool_id: 'us-west-2_98wyKU1mU',
//   aws_appsync_web_client_id: '2vf24te299bvo57qc06usvh7ec',

//   aws_user_files_s3_bucket_region: 'us-west-2', // (required) - Amazon S3 bucket region
//   aws_user_files_s3_bucket: 'https://checkit-media.s3.us-west-2.amazonaws.com',
//   aws_mobile_analytics_app_region: 'us-west-2', // (required) Amazon Pinpoint Project region
// };

const PRODUCTION = {
  aws_project_region: 'us-west-2',
  aws_cognito_region: 'us-west-2', // (required) - Region where Amazon Cognito project was created
  aws_user_pools_id: 'us-west-2_DCKMCeIE5', // (optional) -  Amazon Cognito User Pool ID
  aws_user_pools_web_client_id: 'p9ko4j4c2gsob4eq6va5fdgam', // (optional) - Amazon Cognito App Client ID (App client secret needs to be disabled)
  // (optional) - Users are not allowed to get the aws credentials unless they are signed in
  // aws_mandatory_sign_in: 'enable',

  aws_appsync_region: 'us-west-2', // (optional) - AWS AppSync region
  aws_appsync_graphqlEndpoint: 'https://7dklchmz5nd67iekxgbl66zcie.appsync-api.us-west-2.amazonaws.com/graphql',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_appsync_apiKey: 'da2-5cvmpm4c25f4xjc22c3kevjnne',

  aws_appsync_userpool_id: 'us-west-2_DCKMCeIE5',
  aws_appsync_web_client_id: 'p9ko4j4c2gsob4eq6va5fdgam',

  aws_user_files_s3_bucket_region: 'us-west-2', // (required) - Amazon S3 bucket region
  aws_user_files_s3_bucket: 'https://xpfy-user-media-production.s3.us-west-2.amazonaws.com',
  aws_mobile_analytics_app_region: 'us-west-2', // (required) Amazon Pinpoint Project region
};

export default PRODUCTION;
