/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable camelcase */
import React, {
  useState, useMemo, useRef, useCallback,
} from 'react';
import './PostViewer.css';
import {
  Favorite,
  FavoriteBorder,
  Bookmark,
  BookmarkBorder,
  Info,
  MoreHoriz,
  Tune,
  MessageOutlined,
  Share as ShareIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import ProgressBar from './ProgressBar';
import { useAppState } from '../context/app-context';
// import ActivityIndicator from './ActivityIndicator';
import VirtualizedList from './VirtualizedList';
import Video from './Video';
import Image from './Image';
import DateRange from './DateRange';

const IconMap = {
  favorite: Favorite,
  'favorite-outline': FavoriteBorder,
  bookmark: Bookmark,
  'bookmark-outline': BookmarkBorder,
  info: Info,
  'more-horiz': MoreHoriz,
  tune: Tune,
  'messenger-outline': MessageOutlined,
  share: ShareIcon,
};

// const ITEM_SIZE = 600;

function Icon({
  name, color, size, ...props
}) {
  const IconComponent = IconMap[name];
  return <IconComponent {...props} sx={{ color, fontSize: '1.5rem' }} />;
}

const styles = {
  container: {
    width: '100%',
    height: '100%',
  },
  video: {
    height: '100%',
    width: '100%',
  },
  overlay: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    padding: 10,
  },
  progressBars: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    gap: 10,
  },
  overlayMenu: {
    flexDirection: 'column',
  },
  description: {
    color: 'white',
    fontSize: 14,
    marginBottom: 10,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: 50,
    height: 50,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    marginLeft: 'auto',
    marginRight: 0,
  },
  row: {
    display: 'flex',
    flex: '1 1 0%',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 5,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  profileImage: {
    width: 32,
    height: 32,
    borderRadius: 16,
    marginRight: 16,
    borderColor: 'rgb(187, 187, 187)',
    borderWidth: 1,
  },
  user: {
    fontSize: 16,
    fontWeight: 'bold',
    color: 'white',
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  likeCount: {
    color: '#fff',
    fontSize: 12,
  },
  headerComponentPosition: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    // backgroundColor: 'blue',
    zIndex: 999,
    minHeight: 50,
  },
  dateContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
};

// const SEPARATOR_SIZE = 15;

const getProfilePicture = (userSub) => (window?.location?.hostname === 'localhost'
  ? `https://sandbox.xpfy.app/media/${userSub}/profile.jpg`
  : `/media/${userSub}/profile.jpg`);
const getVideoUrl = (postId, userSub) => (window?.location?.hostname === 'localhost'
  ? `https://sandbox.xpfy.app/media/${userSub}/${postId}.mp4`
  : `/media/${userSub}/${postId}.mp4`);
const getVideoPosterUrl = (postId, userSub) => (window?.location?.hostname === 'localhost'
  ? `https://sandbox.xpfy.app/media/${userSub}/${postId}.720.jpg`
  : `/media/${userSub}/${postId}.720.jpg`);

const fallback = '/images/fallbackProfile.png';

function ItemComponent(props) {
  const {
    item, index, extraData, width, height,
  } = props;
  const {
    isActive,
    onOpenProfile,
    customActionBehavior,
    activeIndex,
  } = extraData;
  const {
    durations = [],
    id,
    description,
    // music,
    userSub: user,
    userProfile = {},
    likes: likeCount = 0,
    bookmarks: bookmarkCount = 0,
    startDate,
    endDate,
    isEvent,
  } = item;
  const { userName } = userProfile || {};
  const [progress, setProgress] = useState([0, 0]);
  const videoRef = useRef();

  const { state, dispatch } = useAppState();
  const {
    post_info, post_comments, post_more, post_music,
  } = state.Sheets;
  // const { isAuthenticated } = state.Auth;
  const { muted = true } = state.Feed;
  const paused = useMemo(() => (
    !isActive
      || index !== activeIndex
      || [post_info, post_comments, post_more, post_music].some(
        (value) => value !== -1,
      )
  ), [
    activeIndex,
    isActive,
    post_info,
    post_comments,
    post_more,
    post_music,
    index,
  ]);

  const uri = useMemo(() => getVideoUrl(id, user), [id, user]);
  const poster = useMemo(() => getVideoPosterUrl(id, user), [id, user]);
  const isLiked = false; // useCache(["likes", id], "is_liked");
  const isBookmarked = false; // useCache(["bookmarks", id], "is_bookmarked");

  const onLike = () => {
    // TODO: Download/Open in App
    // if (!isAuthenticated) {
    //   navigation.navigate("AuthStack");
    //   return;
    // }
    // dispatch({
    //   type: "cache_interaction",
    //   id,
    //   interactionType: !isLiked ? "likes" : "unlikes",
    // });
    // setLikeCount((cur) => (isLiked ? cur - 1 : cur + 1));
  };

  const onBookmark = () => {
    // TODO: Download/Open in App
    // if (!isAuthenticated) {
    //   navigation.navigate("AuthStack");
    //   return;
    // }
    // dispatch({
    //   type: "cache_interaction",
    //   id,
    //   interactionType: !isBookmarked ? "bookmarks" : "unbookmarks",
    // });
    // setBookmarkCount((cur) => (isBookmarked ? cur - 1 : cur + 1));
  };

  const onOpenSheet = useCallback(
    (key) => {
      if (customActionBehavior) return customActionBehavior({ action: key, post: item });
      return dispatch({ type: 'set_field', value: 'open', path: ['Sheets', key] });
    },
    [dispatch, item],
  );

  const onShare = async () => {
    // TODO: implement for web/ maybe copy to clipboard
  };

  const findCurrentDuration = (currentTime) => {
    let cumulativeTime = 0;
    let durIndex = 0;

    for (let i = 0; i < durations.length; i += 1) {
      cumulativeTime += durations[i];
      if (currentTime <= cumulativeTime) {
        durIndex = i;
        break;
      }
    }

    const timeIntoCurrentDuration = currentTime - (cumulativeTime - durations[durIndex]);
    const currentDurationProgress = timeIntoCurrentDuration / durations[durIndex];

    return [durIndex, currentDurationProgress];
  };

  const onProgress = (event) => {
    const { currentTime, duration } = event;
    if (durations?.length > 1) {
      setProgress(findCurrentDuration(currentTime));
    } else {
      if (!duration) return;
      const newProgress = currentTime / duration;
      setProgress([0, newProgress]);
    }
  };

  const onToggleMute = () => {
    dispatch({
      type: 'toggle_mute',
    });
  };

  return (
    <div style={styles.video} key={id}>
      <div style={styles.video}>
        <Video
          ref={videoRef}
          paused={paused}
          uri={uri}
          muted={muted}
          onToggleMute={onToggleMute}
          onProgress={onProgress}
          width={width}
          height={height}
          poster={poster}
        />
      </div>
      {/* TODO: Add Shadow */}
      <div
        // offset={[0, -1]}
        style={styles.overlay}
        // distance={200}
        // startColor="#00000090"
        // endColor="#00000000"
      >
        <div style={styles.overlayMenu}>
          <div style={styles.buttonContainer} onClick={onBookmark}>
            <div style={styles.iconContainer}>
              <Icon
                name={isBookmarked ? 'bookmark' : 'bookmark-outline'}
                size={25}
                color="#fff"
              />
              <p style={styles.likeCount}>{bookmarkCount}</p>
            </div>
          </div>
          <div style={styles.buttonContainer} onClick={onLike}>
            <div style={styles.iconContainer}>
              <Icon
                name={isLiked ? 'favorite' : 'favorite-outline'}
                size={25}
                color="#fff"
              />
              <p style={styles.likeCount}>{likeCount}</p>
            </div>
          </div>
          <div
            className="post-action"
            style={styles.buttonContainer}
            onClick={() => onOpenSheet('post_info')}
          >
            <Icon name="info" size={25} color="#fff" />
          </div>
          <div
            className="post-action"
            style={styles.buttonContainer}
            // onClick={() => onOpenSheet("post_comments")}
            onClick={() => onOpenSheet('post_info')}
          >
            <Icon name="messenger-outline" size={25} color="#fff" />
          </div>
          <div style={styles.buttonContainer} onClick={onShare}>
            <Icon name="share" size={25} color="#fff" />
          </div>
          {/* <div
            className="post-action"
            style={styles.buttonContainer}
            // onClick={() => onOpenSheet("post_more")}
            onClick={() => onOpenSheet('post_info')}
          >
            <Icon name="more-horiz" size={25} color="#fff" />
          </div> */}
        </div>

        <div style={styles.dateContainer}>
          <DateRange
            startDate={startDate}
            endDate={endDate}
            isEvent={isEvent}
            onPress={() => onOpenSheet('post_info')}
          />
        </div>
        <div style={styles.row} onClick={() => onOpenProfile(user)}>
          <div style={styles.row}>
            <Image
              source={{ uri: getProfilePicture(user) }}
              defaultSource={fallback}
              style={styles.profileImage}
            />
            <p style={styles.user}>{`${userName} `}</p>
            {/* <FollowButton userSub={user} /> */}
          </div>
        </div>

        <div onClick={() => onOpenSheet('post_info')}>
          <p style={styles.description} className="two-lines">
            {description}
          </p>
        </div>
        {/* {music ? (
          <ScrollingMusicDisplay
            song={song}
            artist={artist}
            onClick={() => onOpenSheet("post_music")}
          />
        ) : null} */}
        <div style={styles.progressBars}>
          {durations.map((_, videoIndex) => {
            let thisProgress = progress[1] * 100;
            if (progress[0] !== videoIndex) {
              thisProgress = progress[0] < videoIndex ? 0 : 100;
            }
            const key = `duration-${videoIndex}`;
            return (
              <ProgressBar
                key={key}
                progress={thisProgress}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

function PostViewer({
  getMorePosts = () => {},
  posts = [],
  HeaderComponent,
  initialIndex = 0,
  customActionBehavior,
  onActivePostChange = () => {},
}) {
  const navigate = useNavigate();
  const isActive = true; // isFocused && isForeground;
  const [activeIndex, setActiveIndex] = useState(initialIndex);

  const onSnap = (index) => {
    onActivePostChange(index);
    setActiveIndex(index);
  };

  const onOpenProfile = (user) => {
    navigate(`/profile/${user}`);
    // navigation.navigate("Tabs", {
    //   screen: "Profile",
    //   params: { userSub: user },
    // });
  };

  const renderItem = useCallback(
    (itemProps) => <ItemComponent {...itemProps} />,
    [],
  );

  return (
    <>
      <VirtualizedList
        initialScrollIndex={initialIndex}
        data={posts}
        ItemRenderer={renderItem}
        onSnap={onSnap}
        onEndReachedThreshold={1000}
        onEndReached={() => getMorePosts()}
        // ItemSeparatorComponent={renderSeparator}
        // ListFooterComponent={renderFooter}
        // ListEmptyComponent={renderEmpty}
        keyGetter={({ id }) => id}
        extraData={{
          isActive,
          activeIndex,
          onOpenProfile,
          customActionBehavior,
        }}
      />
      {HeaderComponent ? (
        <div style={styles.headerComponentPosition}>
          {/* <HeaderComponent post={posts?.[activeIndex] || {}} /> */}
        </div>
      ) : null}
    </>
  );
}

export default PostViewer;
